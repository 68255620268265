import React, { useState, useContext } from 'react';
import './About.scss'
import { Parallax } from 'react-parallax';
import Image from '../../assets/banner.png';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import CustomButton from '../../Components/CustomButton/CustomButton';
import ImageLoader from 'react-imageloader';
import BorderedCard from '../../Components/BorderedCard/BorderedCard';
import MissionImage from '../../assets/new-vision.png';
import Section from '../../Components/Section/Section';
import TeamItem from '../../Components/TeamItem/TeamItem';
import { ThemeContext } from '../../utils/ThemeContext';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrganizationDetails } from '../../redux/actions/organizationInfo';
import { fetchTeams } from '../../redux/actions/Teams';
import { useEffect } from 'react';
import { getImageUrl } from '../../utils/ImageUrl';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import ReactPlayer from 'react-player';

const About = (props) => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const Organization = useSelector(state => state.Organization);
  const Teams = useSelector(state => state.Teams);
  const [vPlay, setVPlay] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganizationDetails());
    dispatch(fetchTeams('status=true'));
  }, [0])

  return (
    <React.Fragment>
      {Organization.info &&
        <Container className="about-page py-5">
          <Row className="align-items-start">
            <Col lg={6} className="p-lg-0">
              <div className="video-container">
                <img className="thumnail" src={Organization.info && Organization.info.coverVideoImage ? getImageUrl(Organization.info.coverVideoImage.url) : 'http://placehold.jp/1125x750.png'} alt="About Section" />
                <div className="overlay-content d-flex justify-content-center align-items-center">
                  {Organization.info && Organization.info.coverVideo &&
                    <div>
                      <a class="play-btn" onClick={() => {
                        setVPlay(true)
                      }}></a>
                    </div>
                  }
                </div>
              </div>
            </Col>
            <Col lg={6} className="pl-lg-5">
              <h2 className="text-bold">Welcome to Atharva Business College</h2>
              <p className="text-paragraph light py-4" dangerouslySetInnerHTML={{ __html: Organization.info && Organization.info.organizationIntroduction }}>
              </p>
            </Col>
          </Row>
          <Row className="mt-5 pt-5 justify-content-around mission-vision">
            <Col lg={5} className="mb-4 mb-md-0">
              <BorderedCard left bottom borderColor={theme.secondaryColor} className="p-4" title="Vision">
                <p>
                  {Organization.info.vision && Organization.info.vision}
                </p>
              </BorderedCard>

            </Col>
            <Col lg={5} className="mb-4 mb-md-0">
              <BorderedCard right bottom borderColor={'green'} className="p-4" title="Mission">
                <p>
                  {Organization.info && Organization.info.mission}
                </p>
              </BorderedCard>
            </Col>
            <Col lg={12} className="d-flex justify-content-center my-5">
              {/* <MissionIcon width="200" height="200" /> */}
              <img src={MissionImage} alt="Mission" width="300" />
            </Col>
            <Col lg={5} className="mb-4 mb-md-0">
              <BorderedCard left top borderColor={'orange'} className="p-4" title="Core Values">
                <p dangerouslySetInnerHTML={{ __html: Organization.info.objectives && Organization.info.objectives }}>

                </p>
              </BorderedCard>
            </Col>
            {/* <Col lg={5} className="mb-4 mb-md-0">
              <BorderedCard right top borderColor={'#004da7'} className="p-4" title="Goals">
                <p dangerouslySetInnerHTML={{ __html: Organization.info.goals && Organization.info.goals }}>

                </p>
              </BorderedCard>
            </Col> */}
          </Row>
          <Row className="my-5 justify-content-center">
            <Col lg={4}>
              <CustomButton className="mb-4 mb-md-0" onClick={() => history.push('/courses')} variant="primary" type="bordered" text="Find Courses" size="big" />
            </Col>
            <Col lg={4}>
              <CustomButton onClick={() => history.push('/contact-us')} variant="primary" type="bordered" text="Request Information" size="big" />
            </Col>
          </Row>

        </Container>
      }
      <Row className="my-5 justify-content-center">
        {Organization.fetching &&
          <Loading />
        }

        {!Organization.fetching && !Organization.info &&
          <EmptyHolder text="Organization Info Not Available" />
        }
      </Row>
      <Section title="Management Team"
        // description="this is brilliant team" 
        background="gray">
        <Row className="justify-content-center">

          <Col lg={9}>
            <Row className="m-0 mn-md-3">
              {Teams.teams && Teams.teams.map((t, i) => {
                return (
                  <>
                    {i % 5 === 0 && <Col xs={6} lg={1}></Col>}
                    <Col lg={2} className="mb-4">
                      <TeamItem data={t} />
                    </Col>
                  </>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Section>
      <Row className="my-5 justify-content-center">
        {Teams.fetching &&
          <Loading />
        }

        {!Teams.fetching && Teams.teams.length === 0 &&
          <EmptyHolder text="Organization Info Not Available" />
        }
      </Row>
      <Modal
        show={vPlay}
        className="videoModal"
        onHide={() => { setVPlay(false) }}
      >
        <Modal.Body>
          <ReactPlayer
            url={Organization && Organization.info && Organization.info.coverVideo}
            stopOnUnmount
            playing
            controls
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default About;